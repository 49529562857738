/**
 * @version     $Id$
 * @package     PageFly_Affiliate_System
 * @subpackage  Client
 * @author      PageFly Team <admin@pagefly.io>
 * @copyright   Copyright (C) 2019 PageFly.io. All Rights Reserved.
 * @license     GNU/GPL v2 or later http://www.gnu.org/licenses/gpl-2.0.html
 */

.overlay-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: auto;
    height: auto;
    z-index: 9999;
    color: white;
    background-color: rgba(0, 0, 0, .66);
}