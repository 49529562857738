/**
 * @version     $Id$
 * @package     PageFly_Shared_Library
 * @author      PageFly Team <admin@pagefly.io>
 * @copyright   Copyright (C) 2019 PageFly.io. All Rights Reserved.
 * @license     GNU/GPL v2 or later http://www.gnu.org/licenses/gpl-2.0.html
 */

.reorder-items {
    cursor: pointer;
}

.table td:last-child {
    position: relative;
}

.table-row-menu {
    position: absolute;
    top: 50%;
    right: 0;
    width: 33.3%;
    min-width: 96px;
    max-width: 128px;
    height: 48px;
    margin-top: -24px;
    overflow: hidden;
}

.table-row-menu:hover {
    width: auto;
    min-width: initial;
    max-width: initial;
}

.table-row-menu .navbar {
    height: inherit;
    padding: 0;
}

.table-row-menu .nav-link {
    padding: .75em;
    white-space: nowrap;
}

.table-bordered tfoot td {
    border-top-width: 2px;
}
